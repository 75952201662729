<style scoped>
.forget-password-text {
  font-size: 10px;
  cursor: pointer;
  color: #4066b3;
}

.login-card {
  padding-top: 40px;
  margin-top: 60px;
  padding-bottom: 40px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
}

@media screen and (max-width: 960px) {
  .login-card {
    padding-top: 20px;
    margin-top: 30px;
    padding-bottom: 40px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2) !important;
  }
}

.text_un {
  text-decoration: underline;
  text-decoration-color: currentcolor;
  text-decoration-thickness: auto;
  -webkit-text-decoration-color: #5c80bc;
  text-decoration-color: #5c80bc;
  text-underline-position: under;
  text-decoration-thickness: 3px;
}
</style>
<template>
  <v-app>
    <v-row class="mt-15" justify="center">
      <v-col cols="12" md="4" sm="12">
        <div class="text-center mb-5">
          <img alt="" src="@/assets/images/logo.png" />
        </div>

        <v-card class=" px-12 elevation-3 login-card" tile>
          <p class="title"><span class="text_un">Lo</span>gin</p>
          <v-form ref="login_form" lazy-validation @submit.prevent="login()">
            <v-text-field
              v-model="mobile"
              :label="$lang.MOBILE"
              :placeholder="$lang.MOBILE"
              :rules="$rules.MOBILE"
              maxlength="10"
            ></v-text-field>
            <div v-if="!otp_token" class="text-center">
              <v-btn
                :loading="login_loading"
                class="px-10 my-3"
                color="secondary_2"
                rounded
                @click="login"
              >
                <b style="color: #ffff">{{ $lang.CONTINUE }}</b>
              </v-btn>
            </div>
            <div v-else>
              <v-text-field
                v-model="otp"
                :label="$lang.OTP"
                :rules="$rules.OTP"
                maxlength="5"
                placeholder="*****"
              ></v-text-field>
              <small class="d-none">{{ otp_text }}</small>
              <p class="text-right  forget-password-text " @click="resendOtp">
                {{ $lang.RESEND_OTP }}
              </p>
              <div class="text-center">
                <v-btn
                  :loading="verify_loading"
                  class="px-10 my-3"
                  color="secondary_2"
                  rounded
                  @click="verify"
                >
                  <b style="color: #ffff"> {{ $lang.LOGIN }}</b>
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <SnakeBar></SnakeBar>
  </v-app>
</template>

<script type="text/javascript">
export default {
  components: {
    SnakeBar: () => import("@/components/ui/SnakeBar.vue"),
  },
  data() {
    return {
      mobile: "",
      otp: "",
      otp_text: "",
      otp_token: "",
      login_loading: false,
      verify_loading: false,
    };
  },
  mounted() {
    // token for staging
    if (process.env.NODE_ENV === "staging") {
      // sessionStorage.setItem("token","f6719ffa789492dcf8eaea0bda8b6f8cdb51b7f7") // Production
      sessionStorage.setItem(
        "token",
        "8ebd102e8c19dcac0133740fb2c484e9a8e0fcdf"
      ); // Staging
      sessionStorage.setItem(
        "permission_list",
        '["LAS" ,"Business Loan","Refresh Request", "Notification Panel", "Business Loan details","Customer","Manual Payment","Pre Verification","Loan Applied","Team","EMI Analysis","Payment","Review","Manage your Team","Customer details","Export Customer CSV","Service Area","Manage User Contacts","Manage FAQ","Session Detail","Session History","New Customer","View All Documents", "PL Funnel"]'
      );
    }
  },
  methods: {
    /* request for login */
    login() {
      const self = this;
      if (this.$refs.login_form.validate() == false) return false;
      self.login_loading = true;
      var form = new FormData();
      form.append("mobile", self.mobile);
      form.append("account_type", self.$keys.ADMIN);

      const successHandler = (response) => {
        if (response.data.success) {
          self.otp_token = response.headers["otp-token"];
          // self.otp = response.data["otp"]
          self.otp_text = response.data["otp"];
        }
      };
      const finallyHandler = () => {
        self.login_loading = false;
      };
      this.request_POST(
        self,
        this.$urls.LOGIN,
        form,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    /* submit otp and verify data */
    verify() {
      if (this.otp.length != 5) return false;
      const self = this;
      self.verify_loading = false;
      var form = new URLSearchParams();
      form.append("otp", this.otp);

      var header = {
        "otp-token": self.otp_token,
      };
      const successHandler = (response) => {
        if (response.data.success) {
          sessionStorage.setItem("token", response.headers.token);
          this.$store.dispatch(
            "setPermissionList",
            response.data.permission_list
          );
          sessionStorage.setItem(
            "permission_list",
            JSON.stringify(response.data.permission_list)
          );

          self.$router.push({ name: "customers" });
        }
      };
      const finallyHandler = () => {
        self.verify_loading = false;
      };
      self.request_POST(
        self,
        self.$urls.VERIFY_OTP,
        form,
        successHandler,
        header
      );
    },
    /* resend OTP */
    resendOtp() {
      const self = this;
      var form = new URLSearchParams();
      var header = {
        "otp-token": self.otp_token,
      };
      const successHandler = (response) => {
        if (response.data.success) {
          self.showSnakeBar("success", "OTP resent");
        }
      };
      self.request_POST(
        self,
        self.$urls.RESEND_OTP,
        form,
        successHandler,
        header
      );
    },
  },
};
</script>
